
a.active {
  color: navy;
  font-weight: bold;
  text-decoration: none;
}

body, div {
  font-size: 14px;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100vh;
}

.App .AppContent {
  background-color: lightgray;
  height: 100px;
  flex: 1 0px;

  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.RegisteredDoctorsApp .AppContent {
  background-color: white;
  padding: 1em;
}

.AppHeader {
  background-color: lightblue;
  flex: 0 0 0;
  padding: 5px;
  text-align: right;
}

.AppFooter {
  background-color: lightblue;
  flex: 0 0 0;
  padding: 3px;
  text-align: right;
}

.RoomsListContainer {
  background-color: #fff;
  min-width: 250px;
  flex: 0;
  overflow-y: scroll;
  overflow-x: hidden;
}

.RoomsListContainer .NewUser {
  color:darkgreen;
}

.RoomsListContainer .Unread {
  font-weight: bold;
}

.RoomsListContainer .Selected {
  color: white !important;
  background-color: navy;
}

.RoomsListContainer .UserElement {

  padding: 0.3em;

  border-bottom: 1px solid gray;
}

.ChatContainer {
  background-color: white;
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.ChatContainer .ChatHeader {
  flex: 0;
  font-size: 18px;
  padding: 0.3em;
}

.ChatContainer .ChatFooter {
  flex: 0;
  display: block;
}

.ChatContainer .ChatFooter textarea {
  width: 80%;
  height: 5em;
}

.ChatContainer .ChatFooter button {
  margin-left: 15px;

}

.ChatContainer .ChatPanel {
  flex: 1;
  background-color: aliceblue;
  overflow-y: scroll;
  overflow-x: hidden;

  display: flex;
  flex-direction: column-reverse;
}



.ChatContainer .ChatPanel .ChatBubble {
  padding: 5px;
  background-color: white;
  margin: 3px;
  margin-bottom: 1em;
  border-radius: 10px;
  width: fit-content;
  max-width: 80%;
}

.ChatPanel  .ChatBubbleDate {
  text-align: center;
  font-size: 10px;
  color: gray;
}

.ChatContainer .ChatPanel .fromMe {
  margin-left: auto;
  background-color: silver !important;
}

.BigButton {
  display: inline-block;
  margin-left: 1em;
  margin-bottom: 5px;
  padding: 0.5em;
  background-color: red;
  cursor: pointer;
}

.description {
  font-size: 9px;
}